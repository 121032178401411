<template>
    <header>
        <div class="logo-wrapper">
            <img class="logo-main" src="@/assets/logo.png" alt="konovalov-it logo" srcset="">
            <h2>Konovalov</h2>
        </div>
        <nav v-if="$route.name !== 'home'">
            <router-link to="/">Домой</router-link>
        </nav>
    </header>
</template>

<style>
header {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--light-bg-color);
}
nav {
    margin-left: auto;
    margin-right: 1em;
}
h2 {
    margin: 5px 0;
}
.logo-wrapper {
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 5px;
    height: fit-content;
    width: fit-content;
}
.logo-main {
    margin: 0 10px;
    height: 70px;
}
</style>