<template>
    <div class="mainView">
        <div class="hero"><span class="slogan">НАШИ РЕШЕНИЯ ДЛЯ ВАШИХ УСПЕХОВ</span></div>
        <div class="projects-wrapper">
            <ul class="projects-gallery">
                <li class="projects-item">
                        <router-link to="/projects/CVR">
                            <div class="projects-icon">
                                <span>
                                    CVR
                                </span>
                            </div>
                        </router-link>
                </li>
                <li class="projects-item">
                    <div class="projects-icon">
                        <router-link to="/projects/crewAccomodation">Ротация экипажей</router-link>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<style>
.mainView {
    background-color: var(--main-bg-color);
}
.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 350px;
    background-image: url('../assets/hero-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.slogan {
    font-weight: 900;
    font-size: 42px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: rgb(205, 233, 128);
    background-color: rgba(0,0,0,0.4);
}
.projects-gallery {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    list-style: none;
}
.projects-item {
    width: 150px;
    height: 150px;
}
.projects-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--light-bg-color);
}
</style>