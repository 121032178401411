import {defineStore} from 'pinia'
import IProjectDescription from '@/interfaces/IProjectDescription'

export const useProjectsStore = defineStore('projectsStore', {
    state: () => ({
        projects: [
            {
                pathName: 'CVR',
                nameRu: 'Речеписец',
                descriptionRu: `Решение, дополняющее штатный CVR. Позволяет в фоновом режиме производить аудиозапись с корпоративных iPad'ов для дальнейшей 
                отправки на ваш внутренний сервер.`
            },
            {
                pathName: 'crewAccomodation',
                nameRu: 'Калькулятор стоимости оборота экипажа',
                descriptionRu: `Небольшое решение, упрощающее расчёт затрат на отправку и размещение экипажей под предстоящие рейсы вне базы.
                Ставки хранятся на ваших серверах. При работе необходимо указать состав экипажа, цены и продолжительность размещения, стоимость билетов.
                Возможен расчёт до трех сегментов.`
            },
        ] as IProjectDescription[]

    })
})