<template>
    <footer>
        <p>Email:
            <a href="mailto:info@konovalov-it.ru" type="email">info@konovalov-it.ru</a>
        </p>
    </footer>
</template>

<style>
footer {
    padding: 15px 0;
    background-color: var(--dark-bg-color);
}
</style>